@import 'settings';
@import 'link';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/utilities';

@import 'utilities';

@import 'font-face';
@import 'icomoon';
@import 'react-redux-toastr/src/styles/index';

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  @include media-breakpoint-down(md) {
    font-size: 15px !important;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  min-height: 100vh;
  html[dir='rtl'] & {
    font-family: $font-family-arabic;
    text-align: right;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: $gray-900;
  font-weight: 500;
}

h1,
.h1 {
  font-size: 3.25rem !important;
  @include media-breakpoint-down(md) {
    font-size: 2.75rem !important;
  }
}

h2,
.h2 {
  font-size: 2.25rem !important;
  font-weight: 700;
  @include media-breakpoint-down(md) {
    font-size: 1.75rem !important;
  }
}

h3,
.h3 {
  font-size: 1.25rem !important;
  font-weight: 600;
  @include media-breakpoint-down(md) {
    font-size: 1rem !important;
  }
}

h4,
.h4 {
  font-size: 1.5rem !important;
}

h5,
.h5 {
  font-size: 1.25rem !important;
}

h6,
.h6 {
  font-size: 1rem !important;
}

a,
button {
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}

.content-wrapper {
  display: flex;
  background: #fff;
  &.fullscreen {
    min-height: 100vh;
  }
}
.Header ~ .content-wrapper {
  @media only screen and (min-width: 992px) {
    html[dir='ltr'] & {
      padding-left: 62px;
    }
    html[dir='rtl'] & {
      padding-right: 62px;
    }
  }
}

.outer {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: calc(100vh - 72px);
}

.container {
  max-width: 1140px !important;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.form-centered {
  max-width: 851px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  &--sm {
    max-width: 500px;
  }
}

.submitButton {
  &--topgap {
    margin-top: 3rem;
  }
}

.row--gutter {
  margin-left: -8px;
  margin-right: -8px;
  & > [class^='col'] {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.font-small {
  font-size: 15px;
}

.font-msmall {
  font-size: 12px !important;
}

.font-sm {
  font-size: 13px !important;
}

.font-xsmall {
  font-size: 14px;
}

.font-xlarge {
  font-size: 18px !important;
}

.text-medium {
  color: $gray-700;
}

.text-green {
  color: #48cc45;
}

.pd-130 {
  html[dir='ltr'] & {
    padding-left: 145px !important;
    @media (max-width: 1199.98px) {
      padding-left: 15px !important;
    }
  }
  html[dir='rtl'] & {
    padding-right: 145px !important;
    @media (max-width: 1199.98px) {
      padding-right: 15px !important;
    }
  }
}

.icon-plus {
  width: 15px;
  height: 15px;
  position: relative;
  display: inline-flex;
  html[dir='ltr'] & {
    margin-right: 12px;
  }
  html[dir='rtl'] & {
    margin-left: 12px;
  }
  &:before,
  &:after {
    content: '';
    width: 13px;
    height: 2px;
    background: $primary;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  &--white {
    &:before,
    &:after {
      background: #fff;
    }
  }
}

.select__control,
.css-yk16xz-control,
.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: #dbdbdb !important;
  &:hover {
    box-shadow: none !important;
    border-color: hsl(0, 0%, 80%) !important;
  }
}

.select__menu {
  z-index: 99 !important;
}

.border-1 {
  border: 1px solid #e8e8e8;
}

.redux-toastr {
  .top-center {
    width: auto;
    margin-left: 0;
    transform: translateX(-50%);
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 650px;
    }
  }
  .toastr {
    opacity: 1;
    min-height: auto;
    padding: 10px 15px;
    color: $gray-900;
    &.rrt-error {
      background-color: #fff7f4;
      .rrt-left-container .toastr-icon {
        fill: #ff7043;
      }
    }
    &.rrt-success {
      background-color: #edf6f6;
      .rrt-left-container .toastr-icon {
        fill: $primary;
      }
    }
    & > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .rrt-left-container,
    .rrt-right-container {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -moz-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      display: inline-flex;
      align-items: center;
    }
    .rrt-left-container {
      .rrt-holder {
        position: relative;
        width: auto;
        height: auto;
        margin: 0;
        top: 0;
        left: 0;
        display: inline-flex;
        align-items: center;
      }
      .toastr-icon {
        margin: 0;
      }
    }
    .rrt-middle-container {
      -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -moz-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      width: 100%;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0;
      display: flex;
      font-weight: 600;
      .rrt-title {
        margin: 0;
        font-size: 15px;
        margin: 0 5px;
        font-weight: 500;
        color: $gray-900;
      }
      .rrt-text {
        font-size: 15px;
        color: $gray-900;
      }
    }
    .close-toastr {
      position: relative;
      opacity: 1;
      color: $gray-900;
      width: auto;
      font-size: 18px;
      padding: 0;
    }
  }
}

.w-600 {
  max-width: 600px;
  width: 100%;
}

.top-0 {
  top: 0;
}

.left-0 {
  html[dir='ltr'] & {
    left: 0;
  }

  html[dir='rtl'] & {
    right: 0;
  }
}

.right-0 {
  html[dir='ltr'] & {
    right: 0;
  }

  html[dir='rtl'] & {
    left: 0;
  }
}

.bottom-0 {
  bottom: 0;
}

.zIndex-9 {
  z-index: 9;
}
.menu__icon.active {
  color: $primary;
}
.menu__text.active {
  color: $primary;
}

.cursor-pointer {
  cursor: pointer;
}

.slick-prev::before,
.slick-next::before {
  color: black !important;
  opacity: 1;
  font-size: 30px !important;
}

.slick-prev {
  // left: 0 !important;
  z-index: 99;
  width: 30px !important;
  height: 30px !important;
}

.slick-next {
  // right: 0 !important;
  z-index: 99;
  width: 30px !important;
  height: 30px !important;
}

.tag {
  padding: 4px 5px 1px 5px;
  font-size: 10px !important;
}

.gutter {
  &-5 {
    margin-left: -5px !important;
    margin-right: -5px !important;

    & > [class^='col'] {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }

  &-8 {
    margin-left: -8px !important;
    margin-right: -8px !important;

    & > [class^='col'] {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }

  &-12 {
    margin-left: -12px !important;
    margin-right: -12px !important;

    & > [class^='col'] {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
}

.py-sm,
.pt-sm {
  padding-top: 2px;
}

.py-sm,
.pb-sm {
  padding-bottom: 2px;
}

.dashboard-col {
  max-width: 45%;
}

.dashboard-wrapper {
  .sidemenu {
    width: 0;
    top: 0;
    height: 100vh;

    &.active {
      width: 255px;
    }
  }
}

.accordion-control {
  min-width: 25px;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #efefef;
  background-color: $white;
  border-radius: 2px;
  cursor: pointer;
  &.collapsed svg {
    transform: rotate(180deg);
  }
}

.csr-registration {
  .custom-radio--lg {
    & + .custom-radio {
      html[dir='ltr'] & {
        padding-left: 12px;
      }
      html[dir='rtl'] & {
        padding-right: 12px;
      }
    }
    input[type='radio'] + label {
      &:before {
        top: 2px;
      }
      &:after {
        top: 7px;
      }
    }
  }
}

@each $name, $color in $grays {
  .text-gray-#{$name} {
    color: $color;
  }

  .bg-gray-#{$name} {
    background-color: $color;
  }
}

.line-height-normal {
  line-height: normal;
}

.table-studentcdd {
  .table {
    [data-title='StudentID'] {
      min-width: 205px;
    }
  }
}

.css-12jo7m5 {
  padding: 5px 6px 3px 6px !important;
}

.minw-0 {
  min-width: 0;
}
.react-datepicker-popper {
  z-index: 99 !important;
}
.Report {
  .Invoice {
    max-width: 100% !important ;
  }
  h3 {
    font-size: 20px !important;
    font-weight: 600;
  }
}
.ScrollbarsCustom {
  .ScrollbarsCustom-Wrapper {
    width: 100%;
  }
  .ScrollbarsCustom-Track {
    background-color: rgba($dark, 0.05) !important;
    z-index: 9;
    &.ScrollbarsCustom-TrackX {
      height: 5px !important;
      bottom: 5px !important;
    }

    &.ScrollbarsCustom-TrackY {
      width: 4px !important;
      height: 100% !important;
      top: 0 !important;
    }

    .ScrollbarsCustom-Thumb {
      background-color: rgba($dark, 0.1) !important;
    }
  }
}
.icon-close.h3 {
  font-size: 28px !important;
}
.Input__field--icon + i.icon-search {
  top: 42px;
  bottom: inherit;
  height: 17px;
}

.custom-table {
  table {
    border: 1px solid $border-color;
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      border: 1px solid $border-color;
      line-height: 20px;
      line-height: 19px;
    }

    th {
      font-size: 13px;
      font-weight: 600;
      padding: 12px 15px 8px 15px;
      white-space: nowrap;
    }

    td {
      font-size: 12px;
      padding: 14px 15px 10px 15px;
    }
  }
}
