@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon.eot?bk95jb');
  src: url('../assets/fonts/icomoon.eot?bk95jb#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?bk95jb') format('truetype'),
    url('../assets/fonts/icomoon.woff?bk95jb') format('woff'),
    url('../assets/fonts/icomoon.svg?bk95jb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-down:before {
  content: "\e914";
}
.icon-dashboard .path1:before {
  content: "\e97f";
  color: rgb(39, 43, 42);
}
.icon-dashboard .path2:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(15, 19, 18);
  opacity: 0.2;
}
.icon-dashboard .path3:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(15, 19, 18);
  opacity: 0.2;
}
.icon-dashboard .path4:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(15, 19, 18);
  opacity: 0.2;
}
.icon-course-curriculum:before {
  content: "\e978";
}
.icon-test-user:before {
  content: "\e979";
}
.icon-tickets:before {
  content: "\e97a";
}
.icon-reports:before {
  content: "\e97b";
}
.icon-system-logs:before {
  content: "\e97c";
}
.icon-integrator-DI:before {
  content: "\e97d";
}
.icon-template:before {
  content: "\e97e";
}
.icon-corporate:before {
  content: "\e974";
}
.icon-booking:before {
  content: "\e975";
}
.icon-cashier:before {
  content: "\e976";
}
.icon-customer:before {
  content: "\e977";
}
.icon-edit-box:before {
  content: '\e974';
}
.icon-bill:before {
  content: '\e973';
}
.icon-waiting .path1:before {
  content: '\e96e';
  color: rgb(157, 157, 157);
  opacity: 0.2952;
}
.icon-waiting .path2:before {
  content: '\e96f';
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.icon-waiting .path3:before {
  content: '\e970';
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.icon-waiting .path4:before {
  content: '\e971';
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.icon-waiting .path5:before {
  content: '\e972';
  margin-left: -1em;
  color: rgb(157, 157, 157);
}
.icon-branch:before {
  content: '\e965';
  color: #969696;
}
.icon-absent .path1:before {
  content: '\e963';
  color: rgb(230, 92, 92);
}
.icon-absent .path2:before {
  content: '\e966';
  margin-left: -1em;
  color: rgb(230, 92, 92);
  opacity: 0.3031;
}
.icon-absent .path3:before {
  content: '\e967';
  margin-left: -1em;
  color: rgb(230, 92, 92);
}
.icon-absent .path4:before {
  content: '\e968';
  margin-left: -1em;
  color: rgb(230, 92, 92);
}
.icon-absent .path5:before {
  content: '\e969';
  margin-left: -1em;
  color: rgb(230, 92, 92);
}
.icon-passed .path1:before {
  content: '\e964';
  color: rgb(2, 130, 130);
  opacity: 0.2965;
}
.icon-passed .path2:before {
  content: '\e96a';
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-passed .path3:before {
  content: '\e96b';
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-passed .path4:before {
  content: '\e96c';
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-passed .path5:before {
  content: '\e96d';
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-add-user:before {
  content: '\e962';
}
.icon-pdf1 .path1:before {
  content: '\e945';
  color: rgb(208, 226, 224);
}
.icon-pdf1 .path2:before {
  content: '\e946';
  margin-left: -0.9228515625em;
  color: rgb(7, 132, 132);
}
.icon-pdf1 .path3:before {
  content: '\e947';
  margin-left: -0.9228515625em;
  color: rgb(7, 132, 132);
}
.icon-pdf1 .path4:before {
  content: '\e948';
  margin-left: -0.9228515625em;
  color: rgb(7, 132, 132);
}
.icon-pdf1 .path5:before {
  content: '\e949';
  margin-left: -0.9228515625em;
  color: rgb(7, 132, 132);
}
.icon-file-upload .path1:before {
  content: '\e932';
  color: rgb(26, 114, 106);
  opacity: 0.2038;
}
.icon-file-upload .path2:before {
  content: '\e944';
  margin-left: -0.865234375em;
  color: rgb(2, 130, 130);
}
.icon-file-upload .path3:before {
  content: '\e94a';
  margin-left: -0.865234375em;
  color: rgb(2, 130, 130);
}
.icon-file-upload .path4:before {
  content: '\e94b';
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload .path5:before {
  content: '\e94c';
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload .path6:before {
  content: '\e94d';
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload-done .path1:before {
  content: '\e933';
  color: rgb(26, 114, 106);
  opacity: 0.2038;
}
.icon-file-upload-done .path2:before {
  content: '\e94e';
  margin-left: -0.865234375em;
  color: rgb(255, 255, 255);
}
.icon-file-upload-done .path3:before {
  content: '\e94f';
  margin-left: -0.865234375em;
  color: rgb(2, 130, 130);
}
.icon-file-upload-done .path4:before {
  content: '\e950';
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload-done .path5:before {
  content: '\e951';
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload-done .path6:before {
  content: '\e952';
  margin-left: -0.865234375em;
  color: rgb(26, 114, 106);
}
.icon-file-upload-done .path7:before {
  content: '\e953';
  margin-left: -0.865234375em;
  color: rgb(2, 130, 130);
}
.icon-file-upload-done .path8:before {
  content: '\e954';
  margin-left: -0.865234375em;
  color: rgb(255, 255, 255);
}
.icon-refer-friend .path1:before {
  content: '\e939';
  color: rgb(26, 114, 106);
  opacity: 0.202;
}
.icon-refer-friend .path2:before {
  content: '\e93a';
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-support .path1:before {
  content: '\e93b';
  color: rgb(208, 226, 224);
}
.icon-support .path2:before {
  content: '\e93c';
  margin-left: -1em;
  color: rgb(2, 130, 130);
}
.icon-warning .path1:before {
  content: '\e93d';
  color: rgb(19, 177, 187);
}
.icon-warning .path2:before {
  content: '\e93e';
  margin-left: -1.205078125em;
  color: rgb(204, 222, 221);
}
.icon-warning .path3:before {
  content: '\e93f';
  margin-left: -1.205078125em;
  color: rgb(255, 255, 255);
}
.icon-warning .path4:before {
  content: '\e940';
  margin-left: -1.205078125em;
  color: rgb(26, 114, 106);
}
.icon-choose-payment-method .path1:before {
  content: '\e934';
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-choose-payment-method .path2:before {
  content: '\e955';
  margin-left: -0.998046875em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-choose-payment-method .path3:before {
  content: '\e956';
  margin-left: -0.998046875em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-choose-payment-method .path4:before {
  content: '\e957';
  margin-left: -0.998046875em;
  color: rgb(2, 128, 128);
}
.icon-choose-payment-method .path5:before {
  content: '\e958';
  margin-left: -0.998046875em;
  color: rgb(2, 128, 128);
}
.icon-choose-payment-plan .path1:before {
  content: '\e935';
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-choose-payment-plan .path2:before {
  content: '\e959';
  margin-left: -1em;
  color: rgb(2, 128, 128);
}
.icon-choose-payment-plan .path3:before {
  content: '\e95a';
  margin-left: -1em;
  color: rgb(2, 128, 128);
}
.icon-choose-payment-plan .path4:before {
  content: '\e95b';
  margin-left: -1em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-course-type .path1:before {
  content: '\e936';
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-course-type .path2:before {
  content: '\e95c';
  margin-left: -1em;
  color: rgb(2, 128, 128);
}
.icon-course-type .path3:before {
  content: '\e95d';
  margin-left: -1em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-pay-at-eco:before {
  content: '\e937';
  color: #028080;
}
.icon-payonline:before {
  content: '\e938';
  color: #028080;
}
.icon-personal-details .path1:before {
  content: '\e941';
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-personal-details .path2:before {
  content: '\e95e';
  margin-left: -0.7802734375em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-personal-details .path3:before {
  content: '\e95f';
  margin-left: -0.7802734375em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-personal-details .path4:before {
  content: '\e960';
  margin-left: -0.7802734375em;
  color: rgb(2, 128, 128);
}
.icon-promocode .path1:before {
  content: '\e942';
  color: rgb(2, 128, 128);
}
.icon-promocode .path2:before {
  content: '\e961';
  margin-left: -1em;
  color: rgb(2, 128, 128);
  opacity: 0.2;
}
.icon-tick:before {
  content: '\e943';
}
.icon-payment-due:before {
  content: "\e92b";
}
.icon-training:before {
  content: '\e92d';
  color: #008080;
}
.icon-wallet:before {
  content: "\e92e";
}
.icon-file:before {
  content: '\e931';
  color: #008080;
}
.icon-play-btn:before {
  content: '\e929';
}
.icon-pdf-file:before {
  content: '\e92a';
}
.icon-error:before {
  content: '\e927';
}
.icon-up-arrow-on-circle:before {
  content: '\e926';
}
.icon-success:before {
  content: '\e928';
}
.icon-global:before {
  content: '\e923';
}
.icon-icon:before {
  content: '\e924';
}
.icon-placeholder-filled-point:before {
  content: '\e925';
}
.icon-rating:before {
  content: '\e91d';
}
.icon-map:before {
  content: '\e91e';
}
.icon-id-card:before {
  content: '\e91f';
}
.icon-credit-card:before {
  content: '\e920';
}
.icon-contact-book:before {
  content: '\e921';
}
.icon-comment:before {
  content: '\e922';
}
.icon-clock:before {
  content: '\e91b';
}
.icon-clock-circular-outline:before {
  content: '\e91c';
}
.icon-cancel:before {
  content: '\e91a';
}
.icon-pdf:before {
  content: '\e917';
}
.icon-upload:before {
  content: '\e918';
}
.icon-img:before {
  content: '\e919';
}
.icon-star:before {
  content: '\e916';
}
.icon-expand:before {
  content: '\e004';
}
.icon-calendar:before {
  content: '\e915';
}
.icon-admin-user:before {
  content: '\e913';
}
.icon-corporate-user:before {
  content: '\e908';
}
.icon-csr:before {
  content: '\e90a';
}
.icon-instructors:before {
  content: '\e90c';
}
.icon-payment-report:before {
  content: '\e90e';
}
.icon-promotions:before {
  content: '\e90f';
}
.icon-student:before {
  content: '\e910';
}
.icon-supervisor-instructor:before {
  content: '\e911';
}
.icon-system-configuration:before {
  content: '\e912';
}
.icon-arrow-top:before {
  content: '\e900';
}
.icon-arrow-down:before {
  content: '\e975';
}
.icon-chevron-down:before {
  content: '\e901';
}
.icon-chevron-up:before {
  content: '\e902';
}
.icon-edit:before {
  content: '\e903';
}
.icon-ellipse-v:before {
  content: '\e904';
}
.icon-email:before {
  content: '\e905';
}
.icon-menu-up:before {
  content: '\e906';
}
.icon-notification:before {
  content: '\e907';
}
.icon-search:before {
  content: '\e909';
}
.icon-widgets:before {
  content: '\e90b';
}
.icon-arrow-left:before {
  content: '\e90d';
}
.icon-chevron-left:before {
  content: '\36';
}
.icon-chevron-right:before {
  content: '\37';
}
.icon-close:before {
  content: '\23';
}
.icon-eye:before {
  content: '\e973';
  color: #008080;
}
